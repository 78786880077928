import React from "react";
import "./DaySchedule.css";
import DeleteRdvButton from "./DeleteRdvButton/DeleteRdvButton";

const DaySchedule = ({
  daySlots,
  dayIndex,
  rdvs,
  handleMouseDown,
  handleMouseUp,
  handleMouseEnter,
  deleteRdv,
  startIndex,
  endIndex,
  draggingDayIndex,
  openMissionPopup,
}) => {
  return (
    <div className="day">
      <h3>
        {["Lun.", "Mar.", "Mer.", "Jeu.", "Ven."][dayIndex]}{" "}
        {daySlots[0].time.getDate()}
      </h3>
      <div className="time-slots">
        {daySlots.map((time, index) => {
          const isOccupied = rdvs[dayIndex].some((rdv) => {
            return index >= rdv.beginning && index <= rdv.ending - 1;
          });

          return (
            <div
              key={index}
              className={`time-slot ${isOccupied ? "occupied" : ""} ${
                ((startIndex <= index && endIndex >= index) ||
                  (startIndex >= index && endIndex <= index)) &&
                startIndex !== null &&
                endIndex !== null &&
                draggingDayIndex === dayIndex
                  ? "hovered"
                  : ""
              } ${index % 4 === 0 ? "hour-separator" : ""} ${
                index === 35 ? "last-hour-separator" : ""
              }`}
              onMouseDown={() => handleMouseDown(time.time, index, dayIndex)}
              onMouseUp={() => handleMouseUp(time.time, dayIndex)}
              onMouseEnter={() => handleMouseEnter(time.time, index)}
              onDoubleClick={() => openMissionPopup(time.associatedRdv)}
            >
              {time.isRdvStart && (
                <DeleteRdvButton
                  rdvIndex={index}
                  dayIndex={dayIndex}
                  deleteRdv={deleteRdv}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DaySchedule;
