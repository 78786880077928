import React from "react";
import "./DeleteRdvButton.css";

const DeleteRdvButton = ({ rdvIndex, dayIndex, deleteRdv }) => {
  return (
    <button
      className="delete-rdv-button"
      onClick={() => deleteRdv(rdvIndex, dayIndex)}
    >
      <svg
        version="1.1"
        id="icons_1_"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
        width="24"
        height="24"
      >
        <g id="row1_1_">
          <g id="_x34__2_">
            <path
              class="st2"
              d="M64 .3C28.7.3 0 28.8 0 64s28.7 63.7 64 63.7 64-28.5 64-63.7S99.3.3 64 .3zm0 121C32.2 121.3 6.4 95.7 6.4 64 6.4 32.3 32.2 6.7 64 6.7s57.6 25.7 57.6 57.3c0 31.7-25.8 57.3-57.6 57.3zm21.9-73.2L81 43.3c-.9-.9-2.3-.9-3.2 0L64 57 50.2 43.3c-.9-.9-2.3-.9-3.2 0l-4.9 4.8c-.9.9-.9 2.3 0 3.2L55.9 65 42.1 78.8c-.9.9-.9 2.3 0 3.2l4.9 4.8c.9.9 2.3.9 3.2 0L64 73.1l13.8 13.7c.9.9 2.3.9 3.2 0l4.9-4.8c.9-.9.9-2.3 0-3.2L72.1 65l13.8-13.7c.9-.9.9-2.3 0-3.2z"
              id="error_transparent"
            />
          </g>
        </g>
      </svg>
    </button>
  );
};

export default DeleteRdvButton;
