import React, { useEffect, useState } from "react";
import { gsap } from "gsap"; // Import GSAP
import "./NewData.css";

const NewData = ({ setCurrentPage }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    gsap.from(".new-data-container", {
      opacity: 0,
      y: -10,
      duration: 0.5,
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData(event.target);

    try {
      const response = await fetch("/upload-csv", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Upload failed");
      }

      const result = await response.json();
      setMessage("Les données ont été ajoutées avec succès");
      event.target.reset();
    } catch (error) {
      setMessage("Erreur lors de l'upload du fichier: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="new-data-container">
      <h1>Ajouter des clients</h1>
      <form onSubmit={handleSubmit}>
        <input type="file" name="file" accept=".csv" required />
        <button type="submit" disabled={loading}>
          {loading ? "Uploading..." : "Upload"}
        </button>
      </form>
      {message && (
        <p
          className={`message ${
            message.includes("Error") ? "error" : "success"
          }`}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default NewData;
