import "./CalendarHeader.css";

const CalendarHeader = ({ agentName }) => {
  return (
    <div className="calendar-header">
      <h1>{agentName}</h1>
    </div>
  );
};

export default CalendarHeader;
