import { React, useState, useEffect, useRef } from "react";
import "./ModifyForm.css";
import gsap from "gsap";
const ModifyForm = ({ fetchAgents, setShowModifyPopup, agent }) => {
  const [agentData, setAgentData] = useState(agent);
  const formRef = useRef(null);
  useEffect(() => {
    setAgentData(agent);
  }, [agent]);

  const handleChange = (e) => {
    setAgentData({ ...agentData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/update-agent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: agent.id,
          name: agentData.name,
          region: agentData.region,
          adresse: agentData.adresse,
        }),
      });
      fetchAgents();
      setShowModifyPopup(null);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    gsap.from(formRef.current, {
      opacity: 0.3,
      duration: 0.3,
      x: 10,
    });
  }, []);

  return (
    <div
      ref={formRef}
      className="modify-agent-form-container"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="form-content">
        <h2 className="modify-agent-title">Modifier un agent</h2>
        <form className="modify-agent-form" onSubmit={handleSubmit}>
          <div className="form-groups">
            <div className="form-group">
              <label htmlFor="name">Nom de l'agent</label>
              <input
                type="text"
                id="name"
                name="name"
                required
                value={agentData.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="region">Région</label>
              <input
                type="text"
                id="region"
                name="region"
                required
                value={agentData.region}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="adresse">Adresse</label>
              <input
                type="text"
                id="adresse"
                name="adresse"
                required
                value={agentData.adresse}
                onChange={handleChange}
              />
            </div>
          </div>
          <button type="submit">Valider</button>
        </form>
      </div>
    </div>
  );
};

export default ModifyForm;
