import "./DetailsPopup.css";
import { useEffect } from "react";
import React from "react";

export default function DetailsPopup({
  setAdresse,
  setClientName,
  handleSubmit,
  handleCancel,
  adresse,
}) {
  useEffect(() => {
    const input = document.getElementById("adresse");
    const autocomplete = new window.google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: "fr" },
      fields: ["formatted_address"],
    });

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place && place.formatted_address) {
        setAdresse(place.formatted_address);
      }
    });
  }, []);
  return (
    <div className="details-popup">
      <div className="details-popup-content">
        <header>
          <h2>Détails Du Rendez-vous</h2>
        </header>
        <div className="details-popup-content-inputs">
          <div className="details-popup-content-inputs-input">
            <label htmlFor="adresse">Adresse</label>
            <input
              type="text"
              id="adresse"
              value={adresse}
              onChange={(e) => setAdresse(e.target.value)}
            />
          </div>
          <div className="details-popup-content-inputs-input">
            <label htmlFor="clientName">Nom du client</label>
            <input
              type="text"
              onChange={(e) => setClientName(e.target.value)}
            />
          </div>
        </div>
        <div className="details-popup-content-buttons">
          <button onClick={handleSubmit}>Valider</button>
          <button onClick={handleCancel}>Annuler</button>
        </div>
      </div>
    </div>
  );
}
