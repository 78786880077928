import "./NavigationTab.css";
import { useEffect, useState, useRef } from "react";
import gsap from "gsap";

// Change new data
const NavigationTab = ({ setCurrentPage, agentsNames, setCurrentAgent }) => {
  const [currentExpanded, setCurrentExpanded] = useState(null);
  const navigationTabRef = useRef(null);
  const calendarRef = useRef(null);
  const settingsRef = useRef(null);
  const newDataRef = useRef(null);

  function expandSection(ref) {
    if (currentExpanded) {
      gsap.to(currentExpanded.current, {
        maxHeight: 0,
        duration: 0.5,
        ease: "power2.inOut",
      });
    }
    if (currentExpanded !== ref) {
      setCurrentExpanded(ref);
    } else {
      setCurrentExpanded(null);
    }
  }

  useEffect(() => {
    if (currentExpanded) {
      gsap.to(currentExpanded.current, {
        maxHeight: 500,
        duration: 0.5,
        ease: "power2.inOut",
      });
    }
  }, [currentExpanded]);
  return (
    <div className="navigation-tab" ref={navigationTabRef}>
      <div className="navigation-tab-title">
        <p>Menu</p>
      </div>
      <div className="expandable-button-container">
        <div
          className="expandable-button calendar-menu"
          onClick={() => expandSection(calendarRef)}
        >
          <p className="expandable-button-title">Agendas</p>
          <div className="expandable-button-content" ref={calendarRef}>
            {agentsNames.length > 0 &&
              agentsNames.map((agentName) => (
                <p
                  key={agentName}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentPage("calendar");
                    setCurrentAgent(agentName);
                  }}
                  className="expandable-button-content-item"
                >
                  {agentName}
                </p>
              ))}
          </div>
        </div>
        <div
          className="expandable-button settings-menu"
          onClick={() => expandSection(settingsRef)}
        >
          <p className="expandable-button-title">Paramètres</p>
          <div className="expandable-button-content" ref={settingsRef}>
            <p
              onClick={() => setCurrentPage("agents")}
              className="expandable-button-content-item"
            >
              Agents
            </p>
            <p
              onClick={() => setCurrentPage("operations")}
              className="expandable-button-content-item"
            >
              Opérations
            </p>
          </div>
        </div>
        <div
          className="expandable-button new-data-menu"
          onClick={() => setCurrentPage("new_data")}
        >
          <p className="expandable-button-title" ref={newDataRef}>
            Nouveaux clients
          </p>
        </div>
      </div>
    </div>
  );
};

export default NavigationTab;
