import React, { useEffect } from "react";
import "./MissionPopup.css";

const MissionPopup = ({ mission, onClose }) => {
  const getBackToHours = (timeSlot, baseHour = 8) => {
    const minutes = (timeSlot * 15) % 60;
    const hourOffset = Math.floor((timeSlot * 15) / 60);
    const hour = baseHour + hourOffset;

    return `${hour}h${minutes === 0 ? "00" : minutes}`;
  };

  return (
    <div className="mission-popup">
      <div className="mission-popup-content">
        <h2>Détails de la Mission</h2>
        <p>
          <strong>Client:</strong> {mission.client_name}
        </p>
        <p>
          <strong>Adresse:</strong> {mission.adresse}
        </p>
        <p>
          <strong>Date:</strong> {mission.date}
        </p>
        <p>
          <strong>Heure de début:</strong> {getBackToHours(mission.beginning)}
        </p>
        <p>
          <strong>Heure de fin:</strong> {getBackToHours(mission.ending)}
        </p>
        <button onClick={onClose}>Fermer</button>
      </div>
    </div>
  );
};

export default MissionPopup;
