import { React, useState, useEffect } from "react";
import "./CreateAgent.css";
import CreationForm from "./CreationForm/CreationForm";
import ModifyForm from "./ModifyForm/ModifyForm";

const DeleteConfirmationPopup = ({ onConfirm, onCancel }) => {
  return (
    <div className="delete-confirmation-popup">
      <p>Voulez-vous vraiment supprimer cet agent ?</p>
      <div className="delete-confirmation-buttons">
        <button onClick={onConfirm}>Oui</button>
        <button onClick={onCancel}>Non</button>
      </div>
    </div>
  );
};

const CreateAgent = ({
  fetchAgents = () => {},
  setCurrentSettings = () => {},
  agents = [],
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showModifyPopup, setShowModifyPopup] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const deleteAgent = (id) => {
    fetch("/delete-agent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });
    fetchAgents();
    setShowDeleteConfirmation(false);
    setSelectedAgent(null);
  };

  const modifyAgent = (agent) => {
    setSelectedAgent(agent);
    setShowModifyPopup(agent.id);
  };

  return (
    <div className="create-agent-container creation-container">
      <h1>Liste des agents</h1>
      {agents.length === 0 ? (
        <div className="no-agents-message">Aucun agent disponible</div>
      ) : (
        <div className="agent-grid">
          {agents.map((agent) => (
            <div key={agent.id} className="agent-card">
              <p className="agent-name">{agent.name}</p>
              <p>Région : {agent.region}</p>
              <p>Adresse : {agent.adresse}</p>
              <div className="agent-buttons">
                <button onClick={() => modifyAgent(agent)}>Modifier</button>
                <button
                  onClick={() => {
                    setShowDeleteConfirmation(true);
                    setSelectedAgent(agent);
                  }}
                >
                  Supprimer
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      {showPopup && (
        <div
          className="create-agent-popup-container"
          onClick={() => setShowPopup(false)}
        >
          <CreationForm fetchAgents={fetchAgents} setShowPopup={setShowPopup} />
        </div>
      )}
      {showModifyPopup && selectedAgent && (
        <div
          className="create-agent-popup-container"
          onClick={() => setShowModifyPopup(null)}
        >
          <ModifyForm
            fetchAgents={fetchAgents}
            setShowModifyPopup={setShowModifyPopup}
            agent={selectedAgent}
          />
        </div>
      )}
      {showDeleteConfirmation && selectedAgent && (
        <div
          className="create-agent-popup-container"
          onClick={() => {
            setShowDeleteConfirmation(false);
            setSelectedAgent(null);
          }}
        >
          <DeleteConfirmationPopup
            onConfirm={() => deleteAgent(selectedAgent.id)}
            onCancel={() => {
              setShowDeleteConfirmation(false);
              setSelectedAgent(null);
            }}
          />
        </div>
      )}
      <button
        onClick={() => setShowPopup(!showPopup)}
        className={`create-agent-button ${showPopup ? "close" : "open"}`}
      >
        +
      </button>
    </div>
  );
};

export default CreateAgent;
