import { React, useState, useEffect, useRef } from "react";
import "./OperationForm.css";
import gsap from "gsap";

const OperationForm = ({ fetchOperations, setShowPopup, operationToEdit }) => {
  const formRef = useRef(null);
  const [operationData, setOperationData] = useState({
    code: "",
    duration: "",
    name: "",
  });

  useEffect(() => {
    if (operationToEdit) {
      setOperationData(operationToEdit);
    }
  }, [operationToEdit]);

  const handleChange = (e) => {
    setOperationData({ ...operationData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = operationToEdit ? "/update-operation" : "/new-operation";
      const method = operationToEdit ? "PUT" : "POST";

      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(operationData),
      });
      fetchOperations();
      setShowPopup(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    gsap.from(formRef.current, {
      opacity: 0.3,
      duration: 0.3,
      x: 10,
    });
  }, []);

  return (
    <div
      ref={formRef}
      className="operation-form-container"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="form-content">
        <h2 className="operation-title">{operationToEdit ? "Modifier une opération" : "Créer une opération"}</h2>
        <form className="operation-form" onSubmit={handleSubmit}>
          <div className="form-groups">
            <div className="form-group">
              <label htmlFor="name">Nom de l'opération</label>
              <input
                type="text"
                id="name"
                name="name"
                required
                value={operationData.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="code">Code</label>
              <input
                type="text"
                id="code"
                name="code"
                required
                value={operationData.code}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="duration">Durée (en bloc de 15 mins)</label>
              <input
                type="text"
                id="duration"
                name="duration"
                required
                value={operationData.duration}
                onChange={handleChange}
              />
            </div>
          </div>
          <button type="submit">{operationToEdit ? "Mettre à jour" : "Créer"}</button>
        </form>
      </div>
    </div>
  );
};

export default OperationForm;
