import { React, useState, useEffect } from "react";
import "./CreateOperation.css";
import OperationForm from "./OperationForm/OperationForm";

const DeleteConfirmationPopup = ({ onConfirm, onCancel }) => {
  return (
    <div className="delete-operation-confirmation-popup">
      <p>Voulez-vous vraiment supprimer cette opération ?</p>
      <div className="delete-operation-confirmation-buttons">
        <button onClick={onConfirm}>Oui</button>
        <button onClick={onCancel}>Non</button>
      </div>
    </div>
  );
};

const CreateOperation = ({ setCurrentSettings = () => {} }) => {
  const [operations, setOperations] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [operationToEdit, setOperationToEdit] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [operationToDelete, setOperationToDelete] = useState(null);

  const fetchOperations = async () => {
    const response = await fetch("/get-operations");
    const data = await response.json();
    setOperations(data);
  };

  const deleteOperation = async (id) => {
    await fetch("/delete-operation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });
    fetchOperations();
    setShowDeleteConfirmation(false);
  };

  const handleEditOperation = (operation) => {
    setOperationToEdit(operation);
    setShowPopup(true);
  };

  const handleDeleteOperation = (operation) => {
    setOperationToDelete(operation);
    setShowDeleteConfirmation(true);
  };

  useEffect(() => {
    fetchOperations();
  }, []);

  return (
    <div className="creation-container create-operation-container">
      <h1>Toutes les Opérations</h1>
      <ul className="operation-grid">
        {operations.map((operation) => (
          <div key={operation.id} className="operation-card">
            <p className="operation-name">{operation.name}</p>
            <p className="operation-code">CODE : {operation.code}</p>
            <p className="operation-duration">
              Durée : {operation.duration * 15} mins
            </p>
            <button onClick={() => handleEditOperation(operation)}>
              Modifier
            </button>
            <button onClick={() => handleDeleteOperation(operation)}>
              Supprimer
            </button>
          </div>
        ))}
      </ul>
      {showPopup && (
        <div
          className="create-operation-popup-container"
          onClick={() => {
            setShowPopup(false);
            setOperationToEdit(null);
          }}
        >
          <OperationForm
            fetchOperations={fetchOperations}
            setShowPopup={setShowPopup}
            operationToEdit={operationToEdit}
          />
        </div>
      )}
      {showDeleteConfirmation && operationToDelete && (
        <div
          className="create-operation-popup-container"
          onClick={() => {
            setShowDeleteConfirmation(false);
            setOperationToDelete(null);
          }}
        >
          <DeleteConfirmationPopup
            onConfirm={() => deleteOperation(operationToDelete.id)}
            onCancel={() => {
              setShowDeleteConfirmation(false);
              setOperationToDelete(null);
            }}
          />
        </div>
      )}
      <button
        onClick={() => setShowPopup(!showPopup)}
        className={`create-operation-button ${showPopup ? "close" : "open"}`}
      >
        +
      </button>
    </div>
  );
};

export default CreateOperation;
