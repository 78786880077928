import React, { useRef } from "react";
import "./WeekNavigator.css";
import gsap from "gsap";

const WeekNavigator = ({ changeWeek, weekStart, weekEnd }) => {
  const backArrowPathRef = useRef(null);
  const forwardArrowPathRef = useRef(null);
  const formatDate = (date) => {
    if (!date) return "";
    return date.toLocaleDateString("fr-FR", {
      day: "numeric",
    });
  };

  const getMonth = (date) => {
    if (!date) return "";
    return date.toLocaleDateString("fr-FR", {
      month: "short",
    });
  };

  const arrowHover = (ref) => {
    gsap.fromTo(
      ref.current,
      { "--s": "0px", "--o": 0.7 },
      {
        "--s": "50px",
        "--o": 0,
        duration: 1,
      }
    );
  };

  return (
    <div className="change-time">
      <button className="reculer" onClick={() => changeWeek(-1)}>
        <svg
          onMouseEnter={() => arrowHover(backArrowPathRef)}
          version="1.1"
          id="icons_1_"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 128 128"
          fill="currentColor"
        >
          <path
            ref={backArrowPathRef}
            d="M64 0C28.7 0 0 28.7 0 64s28.7 64 64 64 64-28.7 64-64S99.3 0 64 0zm0 121.6C32.2 121.6 6.4 95.8 6.4 64S32.2 6.4 64 6.4s57.6 25.8 57.6 57.6-25.8 57.6-57.6 57.6zM49.2 38.4 73.6 64 49.2 89.6h13.5L86.4 64 62.7 38.4H49.2z"
          ></path>
        </svg>
      </button>
      <span>{`Semaine du ${formatDate(weekStart)} ${
        getMonth(weekStart) === getMonth(weekEnd) ? "" : getMonth(weekStart)
      } - ${formatDate(weekEnd)} ${getMonth(weekEnd)}`}</span>
      <button className="avancer" onClick={() => changeWeek(1)}>
        <svg
          onMouseEnter={() => arrowHover(forwardArrowPathRef)}
          version="1.1"
          id="icons_1_"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 128 128"
          fill="currentColor"
        >
          <path
            ref={forwardArrowPathRef}
            d="M64 0C28.7 0 0 28.7 0 64s28.7 64 64 64 64-28.7 64-64S99.3 0 64 0zm0 121.6C32.2 121.6 6.4 95.8 6.4 64S32.2 6.4 64 6.4s57.6 25.8 57.6 57.6-25.8 57.6-57.6 57.6zM49.2 38.4 73.6 64 49.2 89.6h13.5L86.4 64 62.7 38.4H49.2z"
          ></path>
        </svg>
      </button>
    </div>
  );
};

export default WeekNavigator;
