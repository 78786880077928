import React, { useEffect, useState } from "react";
import "./AgentCalendar.css";
import DetailsPopup from "./DetailsPopup/DetailsPopup";
import DaySchedule from "./DaySchedule/DaySchedule";
import WeekNavigator from "./WeekNavigator/WeekNavigator";
import CalendarHeader from "./CalendarHeader/CalendarHeader";
import MissionPopup from "./MissionPopup/MissionPopup";

const AgentCalendar = ({ currentAgent }) => {
  const [rdvs, setRdvs] = useState([[], [], [], [], []]);
  const [dragging, setDragging] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [detailsPopup, setDetailsPopup] = useState(false);
  const [adresse, setAdresse] = useState("");
  const [clientName, setClientName] = useState(null);

  const [date, setDate] = useState(null);
  const [dayIndex, setDayIndex] = useState(null);
  const [startInterval, setStartInterval] = useState(null);
  const [endInterval, setEndInterval] = useState(null);

  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);
  const [draggingDayIndex, setDraggingDayIndex] = useState(null);

  const [weekStart, setWeekStart] = useState(null);
  const [weekEnd, setWeekEnd] = useState(null);

  const [missionPopup, setMissionPopup] = useState(false);
  const [selectedMission, setSelectedMission] = useState(null);

  useEffect(() => {
    fetchRdvs();
    calculateWeekDates();
  }, [currentAgent, currentWeek]);

  function getOnlyThisWeek(arrays) {
    const today = new Date();
    const startOfWeek = new Date(
      today.setDate(
        today.getDate() -
          (today.getDay() === 0 ? 6 : today.getDay() - 1) +
          7 * currentWeek
      )
    );
    startOfWeek.setHours(0, 0, 0, 0);
    const endOfWeek = new Date(today.setDate(startOfWeek.getDate() + 4));
    endOfWeek.setHours(23, 59, 59, 999);

    const weekArray = [[], [], [], [], []];

    arrays.forEach((array) => {
      const date = new Date(array[0].date);
      date.setHours(0, 0, 0, 0);
      const dayIndex = date.getDay();

      if (
        dayIndex >= 1 &&
        dayIndex <= 5 &&
        date >= startOfWeek &&
        date <= endOfWeek
      ) {
        weekArray[dayIndex - 1] = array;
      }
    });
    return weekArray;
  }

  const fetchRdvs = async () => {
    try {
      const response = await fetch(`/get-rdv-by-agent/${currentAgent}`, {});
      const data = await response.json();
      setRdvs(getOnlyThisWeek(data));
    } catch (error) {
      console.error("Failed to fetch RDVs:", error);
    }
  };

  const handleMouseDown = (time, index, dayIndex) => {
    setDragging(true);
    setStartTime(time);
    setStartIndex(index);
    setDraggingDayIndex(dayIndex);
  };

  const handleMouseUp = (date, dayIndex) => {
    if (startTime !== null && endTime !== null) {
      const startInterval = Math.floor(
        (startTime.getHours() * 60 + startTime.getMinutes() - 480) / 15
      );
      const endInterval = Math.floor(
        (endTime.getHours() * 60 + endTime.getMinutes() - 480) / 15
      );
      setDate(date);
      setDayIndex(dayIndex);
      setStartInterval(startInterval);
      setEndInterval(endInterval);
      setDetailsPopup(true);
    }

    setStartTime(null);
    setEndTime(null);
    setDragging(false);
    setStartIndex(null);
    setEndIndex(null);
    setDraggingDayIndex(null);
  };

  const handleMouseEnter = (time, index) => {
    if (dragging) {
      setEndTime(time);
      setEndIndex(index);
    }
  };

  const createRdv = async (date, dayIndex, start, end) => {
    if (start > end) {
      let temp = start;
      start = end;
      end = temp;
    }

    const isOverlapping = rdvs[dayIndex].some((rdv) => {
      return (
        (start < rdv.ending && start > rdv.beginning) ||
        (end < rdv.ending && end > rdv.beginning)
      );
    });

    if (isOverlapping) {
      console.error("Cannot create RDV: overlapping time slot");
      return;
    }

    try {
      const newRdv = {
        clientName: clientName,
        agentName: currentAgent,
        date: date.toISOString().split("T")[0],
        beginning: start,
        ending: end,
        adresse: adresse,
      };
      const response = await fetch("/new-rdv", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newRdv),
      });

      if (response.ok) {
        fetchRdvs();
      } else {
        console.error("Failed to create RDV:", await response.text());
      }
    } catch (error) {
      console.error("Error creating RDV:", error);
    }
  };

  const deleteRdv = async (index, dayIndex) => {
    if (rdvs.length === 0) return;
    const rdvToDelete = rdvs[dayIndex].find((rdv) => rdv.beginning === index);
    if (rdvToDelete) {
      const rdvId = rdvToDelete.id;
      try {
        const response = await fetch(`/delete-rdv`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: rdvId }),
        });
        if (response.ok) {
          fetchRdvs();
        } else {
          console.error("Failed to delete RDV:", await response.text());
        }
      } catch (error) {
        console.error("Error deleting RDV:", error);
      }
    }
  };

  const generateTimeSlots = () => {
    function startOfWeek(date) {
      var diff =
        date.getDate() +
        currentWeek * 7 -
        date.getDay() +
        (date.getDay() === 0 ? -6 : 1);

      return new Date(date.setDate(diff));
    }

    const slots = [];
    const startOfDay = startOfWeek(new Date());
    startOfDay.setHours(8, 0, 0, 0);

    for (let day = 0; day < 5; day++) {
      const daySlots = [];
      const currentDay = new Date(startOfDay);
      currentDay.setDate(currentDay.getDate() + day);
      for (let i = 0; i < 36; i++) {
        const time = new Date(currentDay);
        time.setMinutes(time.getMinutes() + i * 15);

        const isRdvStart = rdvs[day].some((rdv) => {
          return i === rdv.beginning;
        });
        daySlots.push({
          time: time,
          interval: i,
          isRdvStart: isRdvStart,
          associatedRdv: rdvs[day].find(
            (rdv) => i >= rdv.beginning && i <= rdv.ending
          ),
        });
      }
      slots.push(daySlots);
    }
    return slots;
  };

  useEffect(() => {
    setTimeSlots(generateTimeSlots());
  }, [rdvs]);

  const handleSubmit = () => {
    createRdv(date, dayIndex, startInterval, endInterval);
    setDate(null);
    setDayIndex(null);
    setStartInterval(null);
    setEndInterval(null);
    setDetailsPopup(false);
  };

  const handleCancel = () => {
    setDate(null);
    setDayIndex(null);
    setStartInterval(null);
    setEndInterval(null);
    setDetailsPopup(false);
  };

  const calculateWeekDates = () => {
    const today = new Date();
    const startOfWeek = new Date(
      today.setDate(
        today.getDate() -
          (today.getDay() === 0 ? 6 : today.getDay() - 1) +
          7 * currentWeek
      )
    );
    startOfWeek.setHours(0, 0, 0, 0);
    const endOfWeek = new Date(today.setDate(startOfWeek.getDate() + 4));
    endOfWeek.setHours(23, 59, 59, 999);

    setWeekStart(startOfWeek);
    setWeekEnd(endOfWeek);
  };

  const changeWeek = (offset) => {
    setCurrentWeek(currentWeek + offset);
  };

  const openMissionPopup = (rdv) => {
    setSelectedMission(rdv);
    setMissionPopup(true);
  };

  const closeMissionPopup = () => {
    setSelectedMission(null);
    setMissionPopup(false);
  };

  return (
    <div className="agent-calendar">
      <header className="calendar-header-container">
        <CalendarHeader agentName={currentAgent} />
      </header>
      <WeekNavigator
        changeWeek={changeWeek}
        weekStart={weekStart}
        weekEnd={weekEnd}
      />
      <div className="agent-schedule">
        {detailsPopup && (
          <DetailsPopup
            setAdresse={setAdresse}
            setClientName={setClientName}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            adresse={adresse}
          />
        )}
        <div className="days-container">
          <div className="hour-container">
            <div className="hour">8h</div>
            <div className="hour">9h</div>
            <div className="hour">10h</div>
            <div className="hour">11h</div>
            <div className="hour">12h</div>
            <div className="hour">13h</div>
            <div className="hour">14h</div>
            <div className="hour">15h</div>
            <div className="hour">16h</div>
            <div className="hour">17h</div>
          </div>
          {timeSlots.map((daySlots, dayIndex) => (
            <DaySchedule
              key={dayIndex}
              daySlots={daySlots}
              dayIndex={dayIndex}
              rdvs={rdvs}
              handleMouseDown={handleMouseDown}
              handleMouseUp={handleMouseUp}
              handleMouseEnter={handleMouseEnter}
              openMissionPopup={openMissionPopup}
              deleteRdv={deleteRdv}
              startIndex={startIndex}
              endIndex={endIndex}
              draggingDayIndex={draggingDayIndex}
            />
          ))}
        </div>
      </div>
      {missionPopup && selectedMission && (
        <MissionPopup mission={selectedMission} onClose={closeMissionPopup} />
      )}
    </div>
  );
};

export default AgentCalendar;
