import React, { useEffect, useState } from "react";
import Calendar from "./Calendar/Calendar";
import "./Admin.css";
import Settings from "./Settings/Settings";
import NewData from "./Calendar/NewData/NewData";
import NavigationTab from "./NavigationTab/NavigationTab";
import CreateAgent from "./Settings/CreateAgent/CreateAgent";
import CreateOperation from "./Settings/CreateOperation/CreateOperation";
import AgentCalendar from "./Calendar/AgentCalendar/AgentCalendar";

const Admin = () => {
  const [agents, setAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [currentAgent, setCurrentAgent] = useState(null);

  function fetchAgents() {
    fetch("/get-agents")
      .then((res) => res.json())
      .then((data) => {
        setAgents(data);
      });
  }

  useEffect(() => {
    fetchAgents();
  }, []);

  return (
    <div className="admin-container">
      {agents && (
        <>
          <NavigationTab
            setCurrentPage={setCurrentPage}
            agentsNames={agents.map((agent) => agent.name)}
            setCurrentAgent={setCurrentAgent}
          />
          <div className="admin-content">
            {currentPage === "calendar" && currentAgent && (
              <AgentCalendar currentAgent={currentAgent} />
            )}

            {currentPage === "agents" && (
              <CreateAgent fetchAgents={fetchAgents} agents={agents} />
            )}
            {currentPage === "operations" && <CreateOperation />}

            {currentPage === "new_data" && (
              <NewData setCurrentPage={setCurrentPage} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Admin;
